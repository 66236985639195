<template>
  <section class="addform">
    <div v-if="loading" class="loadingBox">
      <Loading />
    </div>
    <v-row v-if="!loading">
      <v-row>
        <v-col md="6" cols="6">
          <h2 class="mb-5 mt-5">
            <span v-if="!userSell">Upload</span>
            <span v-if="userSell">Upload Your</span> Vehicle
          </h2>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="valid" lazy-validation class="form-section">
        <v-row>
          <v-col md="12" cols="12" class="pt-8 mb-3">
            <v-row>
              <div class="px-3 marBtmMinus5">
                <h3>Medias</h3>
              </div>
              <v-col md="12">
                <vue-dropzone
                  ref="myVueDropzone"
                  id="dropzone"
                  :options="dropzoneOptions"
                ></vue-dropzone>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="12" v-if="imagesUrl.length > 0">
            <div class="d-flex">
              <div
                v-for="(data, index) in imagesUrl"
                :key="index + 'image'"
                class="imageAdded"
              >
                <v-icon @click="removeImages(data)"> mdi-close </v-icon>
                <img :src="data.mediaLink" alt="" />
              </div>
              <div
                v-for="(data, index) in videosUrl"
                :key="index + 'image'"
                class="imageAdded"
              >
                <v-icon @click="removeVideos(data)"> mdi-close </v-icon>
                <video controls>
                  <source :src="data.mediaLink" type="video/mp4" />
                </video>
              </div>
            </div>
          </v-col>
          <v-col md="4" class="pb-0">
            <v-select
              class="inputbox"
              v-model="category"
              label="Category"
              outlined
              :items="categories"
              item-text="name"
              item-value="name"
              @change="selectCategory(category)"
            ></v-select>
          </v-col>
          <v-col md="4" class="pb-0">
            <v-select
              class="inputbox"
              :items="brands"
              v-model="brand"
              label="Brand"
              item-text="brandName"
              item-value="brandName"
              @keyup.enter.stop="validate"
              outlined
            ></v-select>
          </v-col>
          <v-col md="4" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="productName"
              label="Model Name"
              @keyup.enter.stop="validate"
              outlined
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pt-0">
            <v-select
              class="inputbox"
              :items="years"
              v-model="year"
              label="Year"
              item-text="yearName"
              item-value="yearName"
              @keyup.enter.stop="validate"
              outlined
            ></v-select>
          </v-col>
          <v-col md="4" class="pt-0">
            <v-select
              class="inputbox"
              v-model="subCategory"
              label="Sub Category"
              outlined
              :items="subCategories"
              item-text="name"
              item-value="name"
            ></v-select>
          </v-col>

          <v-col md="12" class="pb-0">
            <strong>Pricing</strong>
          </v-col>

          <v-col md="4" class="pb-0">
            <v-text-field
              class="inputbox"
              v-model="price"
              label="Price"
              outlined
              :rules="numberRule"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pb-0" v-if="!userSell">
            <v-text-field
              class="inputbox"
              v-model="discountPercentage"
              label="Discount (Rs)"
              outlined
              :rules="numberRule"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pb-0" v-if="!userSell">
            <v-text-field
              class="inputbox"
              v-model="quantity"
              label="Stock"
              outlined
              :rules="numberRule"
            ></v-text-field>
          </v-col>
          <v-col md="4" class="pb-0">
            <v-checkbox
              class="inputbox"
              v-model="secondHand"
              label="Is it Second Hand ?"
              outlined
              v-if="!userSell"
            ></v-checkbox>
          </v-col>
          <v-col md="4" class="pb-0">
            <v-checkbox
              class="inputbox"
              v-model="featured"
              label="Is it Featured ?"
              outlined
              v-if="!userSell"
            ></v-checkbox>
          </v-col>

          <v-col md="12" cols="12" class="pb-0">
            <strong>Description</strong>
          </v-col>
          <v-col md="12" cols="12" class="pb-5">
            <vue-editor
              class="inputbox"
              v-model="description"
              placeholder="Description"
            ></vue-editor>
          </v-col>
          <v-col md="12" cols="12" class="pb-0">
            <strong>Meta Keywords</strong>
          </v-col>
          <v-col md="12" cols="12" class="pb-5">
            <v-text-field
              outlined
              placeholder="Meta Keywords"
              rows="5"
            ></v-text-field>
          </v-col>
          <v-col md="12" cols="12" class="pb-0">
            <strong>Meta Description</strong>
          </v-col>
          <v-col md="12" cols="12" class="pb-5">
            <v-textarea
              outlined
              placeholder="Meta Keywords"
              rows="5"
            ></v-textarea>
          </v-col>

          <div class="d-flex flex-wrap" v-if="category != 'Accessories'">
            <v-col md="12" class="pb-1">
              <strong>Specification</strong>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                class="inputbox"
                v-model="motor"
                label="Motor Type"
                outlined
                :items="motorTypes"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="range"
                label="Range (km)"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="batteryCapacity"
                label="Battery Capacity"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                class="inputbox"
                :items="batteryTypes"
                v-model="batteryType"
                label="Battery Type"
                item-text="batteryType"
                item-value="batteryType"
                @keyup.enter.stop="validate"
                outlined
              ></v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="acceleration"
                label="Acceleration (0-100kmph)"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="mileage"
                label="Mileage"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="engineDisplacement"
                label="Engine Displacement"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="bhp"
                label="BHP"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                :items="trasmissionTypes"
                class="inputbox"
                v-model="transmissionType"
                label="Transmission Type"
                outlined
                item-text="name"
                item-value="value"
              >
              </v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                multiple
                placeholder="Color"
                label="Color"
                class="inputbox"
                outlined
                :items="colors"
                v-model="color"
                item-text="colorName"
                item-value="colorName"
              ></v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="seatingCapacity"
                label="Seating Capacity"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="cityMileage"
                label="City Mileage"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="fuelType"
                label="Fuel Type"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="numberOfCylinder"
                label="Number of cylinder"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="power"
                label="Max Power (bhp@rpm)"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="torque"
                label="Max Torque (nm@rpm)"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="bootSpace"
                label="Boot Space (litres)"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="fuelTank"
                label="Fuel Tank"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                class="inputbox"
                :items="bodyTypes"
                v-model="bodyType"
                label="Body Type"
                item-text="bodyTypeName"
                item-value="bodyTypeName"
                @keyup.enter.stop="validate"
                outlined
              ></v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                class="inputbox"
                :items="chargingVias"
                v-model="chargingVia"
                label="Charging Station"
                item-text="name"
                item-value="value"
                outlined
              ></v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="groundClearance"
                label="Ground Clearance"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="serviceCost"
                label="Service Cost"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="chargingTime"
                label="Charging Time"
                outlined
              ></v-text-field>
            </v-col>
          </div>

          <div class="widthFull" v-if="false">
            <v-col md="12" cols="12" class="pb-0 pt-0 flexBox flexBox-space">
              <strong>Attributes</strong>
              <v-btn @click="addAttrubuite">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col md="12" class="py-0">
              <div
                class="flexBox pt-5"
                v-for="(data, index) in attributes"
                :key="index + 'attribute'"
              >
                <v-text-field
                  class="inputbox mr-2"
                  v-model="data.attributeKey"
                  label="Key"
                  outlined
                ></v-text-field>
                <v-text-field
                  class="inputbox ml-2"
                  v-model="data.attributeValue"
                  label="Value"
                  outlined
                ></v-text-field>
                <v-icon
                  class="v-icon-miuns mt-5 mr-5"
                  @click="removeAttrubuite(index)"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </div>

          <div class="widthFull">
            <v-col md="12" cols="12" class="pb-0 pt-0 flexBox flexBox-space">
              <strong>Additional</strong>
              <v-btn @click="addFeature">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col md="12" class="py-0">
              <div
                class="flexBox column pt-5"
                v-for="(data, index) in features"
                :key="index + 'attribute'"
              >
                <v-text-field
                  class="inputbox mr-2"
                  v-model="data.title"
                  label="Title"
                  outlined
                ></v-text-field>
                <v-btn @click="addFeatureItem(index)" class="mb-4 addItemBtn">
                  <v-icon> mdi-plus </v-icon> Add Item
                </v-btn>
                <div
                  v-for="(dat, ind) in data.items"
                  :key="ind + 'data'"
                  class="relBox"
                >
                  <v-text-field
                    class="inputbox"
                    v-model="dat.item"
                    label="Item"
                    outlined
                  ></v-text-field>
                  <v-icon
                    class="v-icon-miuns mr-5"
                    @click="removeFeatureItem(index, ind)"
                  >
                    mdi-close
                  </v-icon>
                </div>

                <v-icon
                  class="v-icon-miuns mt-5 mr-8"
                  @click="removeFeature(index)"
                >
                  mdi-close
                </v-icon>
              </div>
            </v-col>
          </div>

          <div class="widthFull">
            <v-col md="12" cols="12" class="pb-0 pt-0 flexBox flexBox-space">
              <strong>Attributes</strong>
              <v-btn @click="addAttrubuite">
                <v-icon> mdi-plus </v-icon>
              </v-btn>
            </v-col>
            <v-col md="12" class="py-0">
              <div
                class="flexBox attributeBox column pt-5"
                v-for="(data, index) in attributes"
                :key="index + 'attribute'"
              >
                <v-text-field
                  class="inputbox mr-2"
                  v-model="data.attributeKey"
                  label="Attribute Key"
                  outlined
                ></v-text-field>

                <v-text-field
                  class="inputbox mr-2"
                  v-model="data.attributeValue"
                  label="Attribute Value"
                  outlined
                ></v-text-field>

                <v-btn @click="removeFeature(index)"> Remove </v-btn>
              </div>
            </v-col>
          </div>

          <div class="widthFull" v-if="false">
            <AddProperty />
          </div>

          <v-col md="12" cols="12" class="submitbtn mb-10">
            <v-btn
              :disabled="!valid"
              large
              @click="validate"
              v-if="submitloading"
            >
              Upload
            </v-btn>

            <v-btn class="submitbutton btn-dark" v-if="!submitloading">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import AddProperty from "@/components/business/product/addProperty";
import Loading from "@/components/Loading";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
export default {
  name: "AddProductComponent",
  components: {
    VueEditor,
    AddProperty,
    Loading,
    vueDropzone: vue2Dropzone,
  },
  props: ["userSell"],

  data: () => ({
    subCategories: [],
    category: undefined,
    categories: [],
    subCategory: undefined,
    brand: undefined,
    productName: undefined,
    price: undefined,
    description: undefined,
    discountPercentage: 0,
    loading: true,
    quantity: undefined,
    valid: true,
    _id: undefined,
    submitloading: true,
    deliveryTime: "",
    percentage: 0,
    images: [],
    videos: [],
    myCroppa: {},
    properties: undefined,
    attributes: [],
    features: [],
    numberRule: [(v) => !isNaN(v) || "Field Should be Number"],
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      headers: { "My-Awesome-Header": "header value" },
      addRemoveLinks: true,
    },
    featured: undefined,
    secondHand: undefined,
    mileage: undefined,
    bhp: undefined,
    cityMileage: undefined,
    fuelTank: undefined,
    engineDisplacement: undefined,
    numberOfCylinder: undefined,
    torque: undefined,
    bodyType: undefined,
    power: undefined,
    seatingCapacity: undefined,
    transmissionType: undefined,
    bootSpace: undefined,
    fuelType: undefined,
    chargingTime: undefined,
    groundClearance: undefined,
    serviceCost: undefined,
    color: undefined,
    colors: [],
    motorTypes: [
      { name: "EV", value: "ev" },
      { name: "Hybrid", value: "hybrid" },
    ],
    trasmissionTypes: [
      { name: "Automatic", value: "automatic" },
      { name: "Manual", value: "manual" },
    ],
    chargingVias: [
      { name: "AC", value: "AC" },
      { name: "DC", value: "DC" },
    ],
    chargingVia: undefined,
    motor: undefined,
    batteryType: undefined,
    batteryCapacity: undefined,
    range: undefined,
    acceleration: undefined,
    year: undefined,
    removedImages: [],
    removedVideos: [],
    model: undefined,
    imagesUrl: [],
    videosUrl: [],
    bodyTypes: [],
    brands: [],
    models: [],
    years: [],
    batteryTypes: [],
    metaKeyword: undefined,
    metaDescription: undefined,
  }),
  methods: {
    ...mapActions([
      "postProduct",
      "openSnackBar",
      "fetchCategory",
      "getSingleFrotendProduct",
      "getBodyTypeList",
      "getBrandList",
      "getColorListSelect",
      "getYearList",
      "getBatteryTypeList",
      "getColorList",
      "getLogin",
    ]),
    removeImages(data) {
      let media = {};
      media.fileLocation = data.mediaLink.replace(
        "http://api.evinnepal.com/api",
        ""
      );
      media._id = data._id;
      this.removedImages.push(media);
      let images;
      images = this.imagesUrl.filter((ele) => {
        return ele._id != data._id;
      });
      this.imagesUrl = images;
    },
    removeVideos(data) {
      let media = {};
      media.fileLocation = data.mediaLink.replace(
        "http://api.evinnepal.com/api",
        ""
      );
      media._id = data._id;
      this.removedVideos.push(media);
      let videos;
      videos = this.videosUrl.filter((ele) => {
        return ele._id != data._id;
      });
      this.videosUrl = videos;
    },
    addFeature() {
      this.features.push({ title: "", items: [{ item: "" }] });
    },
    removeFeature(index) {
      this.features.splice(index, 1);
    },
    addFeatureItem(index) {
      this.features[index].items.push({ item: "" });
    },
    removeFeatureItem(index, indexSub) {
      this.features[index].items.splice(indexSub, 1);
    },
    addAttrubuite() {
      this.attributes.push({
        attributeKey: "",
        attributeValue: "",
      });
    },
    removeAttrubuite(index) {
      this.attributes.splice(index, 1);
    },
    selectCategory(id) {
      this.subCategories = [];
      if (id != undefined) {
        let filteredData = this.allCategory.results.filter((ele) => {
          return id == ele.name;
        });
        if (filteredData.length > 0) {
          this.subCategories = filteredData[0].subCategories;
        }
      }
    },
    async validate() {
      if (this.$refs.form.validate()) {
        this.images = [];
        this.videos = [];
        let fileBundles = this.$refs.myVueDropzone.getAcceptedFiles();
        fileBundles.forEach((ele) => {
          if (ele.type.split("/")[0] == "image") {
            this.images.push(ele);
          } else if (ele.type.split("/")[0] == "video") {
            this.videos.push(ele);
          }
        });

        let formData = new FormData();
        this.submitloading = false;

        if (this._id != undefined) {
          formData.append("_id", this._id);
        }

        if (this.year != undefined) {
          formData.append("year", this.year);
        }

        if (this.category != undefined) {
          formData.append("category", this.category);
        }

        if (this.removedVideos != undefined) {
          formData.append("removedVideos", JSON.stringify(this.removedVideos));
        }
        if (this.removedImages != undefined) {
          formData.append("removedImages", JSON.stringify(this.removedImages));
        }

        if (this.subCategory != undefined) {
          formData.append("subCategory", this.subCategory);
        }
        if (this.productName != undefined) {
          formData.append("productName", this.productName);
        }
        if (this.color != undefined) {
          formData.append("color", JSON.stringify(this.color));
        }
        if (this.description != undefined) {
          formData.append("description", this.description);
        }
        if (this.brand != undefined) {
          formData.append("brand", this.brand);
        }

        if (this.chargingTime != undefined) {
          formData.append("chargingTime", this.chargingTime);
        }

        if (this.price != undefined) {
          formData.append("price", this.price);
        }
        if (this.discountPercentage != undefined) {
          formData.append("discountPercentage", this.discountPercentage / 100);
        }
        if (this.quantity != undefined) {
          formData.append("quantity", this.quantity);
        }
        if (this.batteryCapacity != undefined) {
          formData.append("batteryCapacity", this.batteryCapacity);
        }

        formData.append("features", JSON.stringify(this.features));

        if (this.mileage != undefined) {
          formData.append("mileage", this.mileage);
        }
        if (this.engineDisplacement != undefined) {
          formData.append("engineDisplacement", this.engineDisplacement);
        }
        if (this.bhp != undefined) {
          formData.append("bhp", this.bhp);
        }
        if (this.seatingCapacity != undefined) {
          formData.append("seatingCapacity", this.seatingCapacity);
        }
        if (this.cityMileage != undefined) {
          formData.append("cityMileage", this.cityMileage);
        }
        if (this.fuelType != undefined) {
          formData.append("fuelType", this.fuelType);
        }
        if (this.numberOfCylinder != undefined) {
          formData.append("numberOfCylinder", this.numberOfCylinder);
        }

        formData.append("attributes", JSON.stringify(this.attributes));

        if (this.power != undefined) {
          formData.append("power", this.power);
        }

        if (this.motor != undefined) {
          formData.append("motor", this.motor);
        }
        if (this.batteyCapacity != undefined) {
          formData.append("batteyCapacity", this.batteyCapacity);
        }
        if (this.batteryType != undefined) {
          formData.append("batteryType", this.batteryType);
        }
        if (this.range != undefined) {
          formData.append("range", this.range);
        }
        if (this.acceleration != undefined) {
          formData.append("acceleration", this.acceleration);
        }

        if (this.torque != undefined) {
          formData.append("torque", this.torque);
        }
        if (this.transmissionType != undefined) {
          formData.append("transmissionType", this.transmissionType);
        }
        if (this.bootSpace != undefined) {
          formData.append("bootSpace", this.bootSpace);
        }
        if (this.fuelTank != undefined) {
          formData.append("fuelTank", this.fuelTank);
        }
        if (this.chargingVia != undefined) {
          formData.append("chargingVia", this.chargingVia);
        }
        if (this.secondHand != undefined) {
          formData.append("isSecondHand", this.secondHand);
        }
        if (this.featured != undefined) {
          formData.append("isFeatured", this.featured);
        }
        if (this.bodyType != undefined) {
          formData.append("bodyType", this.bodyType);
        }
        if (this.groundClearance != undefined) {
          formData.append("groundClearance", this.groundClearance);
        }
        if (this.serviceCost != undefined) {
          formData.append("serviceCost", this.serviceCost);
        }

        if (this.metaKeyword != undefined) {
          formData.append("metaKeyword", this.metaKeyword);
        }
        if (this.metaDescription != undefined) {
          formData.append("metaDescription", this.metaDescription);
        }

        if (this.$route.params.id != undefined) {
          formData.append("_id", this.$route.params.id);
        }

        if (this.images != undefined) {
          this.images.forEach((ele) => {
            formData.append("images", ele);
          });
        }

        if (this.videos != undefined) {
          this.videos.forEach((ele) => {
            formData.append("videos", ele.file);
          });
        }

        await this.postProduct(formData);
        this.submitloading = true;
        if (this.userSell) {
          this.$router.push("/myvehicles");
        } else {
          this.$router.push("/business/products");
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "allCategory",
      "allProduct",
      "allBrands",
      "allYears",
      "allColors",
      "allBodyTypes",
      "allBatteryTypes",
      "allLogin",
    ]),
  },
  async created() {
    this.getLogin();
    if (this.allLogin == undefined) {
      this.$router.push("/login");
      // sessionStorage.setItem("page",)
    }
    await this.fetchCategory();
    this.categories = this.allCategory.results;
    await this.getBodyTypeList();
    this.bodyTypes = this.allBodyTypes.results;
    this.bodyTypes.unshift("None");
    await this.getBrandList();
    this.brands = this.allBrands.results;
    await this.getColorListSelect();
    this.colors = this.allColors.results;
    await this.getYearList();
    this.years = this.allYears.results;
    await this.getBatteryTypeList();
    this.batteryTypes = this.allBatteryTypes.results;

    if (this.$route.params.id) {
      await this.getSingleFrotendProduct(this.$route.params.id);
      if (this.allProduct.results.medias.length > 0) {
        this.imagesUrl = this.allProduct.results.medias.filter((ele) => {
          return ele.fileType == "image";
        });
        this.videosUrl = this.allProduct.results.medias.filter((ele) => {
          return ele.fileType == "video";
        });
      }

      this.productName = this.allProduct.results.productName;
      this.color = this.allProduct.results.color;
      this.year = this.allProduct.results.year;
      this.model = this.allProduct.results.model;
      this.brand = this.allProduct.results.brand;
      this.category = this.allProduct.results.category;
      this.subCategory = this.allProduct.results.subCategory;
      this.selectCategory(this.category);
      this.price = this.allProduct.results.price;
      this.discountPercentage =
        this.allProduct.results.discountPercentage * 100;
      this.quantity = this.allProduct.results.quantity;
      this.secondHand = this.allProduct.results.isSecondHand;
      this.featured = this.allProduct.results.isFeatured;
      this.description = this.allProduct.results.description;
      this.motor = this.allProduct.results.motor;
      this.range = this.allProduct.results.range;
      this.batteryCapacity = this.allProduct.results.batteryCapacity;
      this.batteryType = this.allProduct.results.batteryType;
      this.acceleration = this.allProduct.results.acceleration;
      this.transmissionType = this.allProduct.results.transmissionType;
      this.seatingCapacity = this.allProduct.results.seatingCapacity;
      this.power = this.allProduct.results.power;
      this.torque = this.allProduct.results.torque;
      this.bootSpace = this.allProduct.results.bootSpace;
      this.bodyType = this.allProduct.results.bodyType;
      this.groundClearance = this.allProduct.results.groundClearance;
      this.serviceCost = this.allProduct.results.serviceCost;
      this.attributes = this.allProduct.results.attributes;
      this.features = this.allProduct.results.features;
      this.chargingTime = this.allProduct.results.chargingTime;
      this.chargingVia = this.allProduct.results.chargingVia;

      this.loading = false;
    } else {
      this.loading = false;
    }

    this.secondHand = this.userSell;
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.marBtmMinus5 {
  margin-bottom: -20px;
}
.attributeBox {
  .v-btn {
    background: red !important;
    min-height: 50px;
    width: 200px;
  }
}
.submitbtn {
  text-align: left;
  color: #fff;
  display: flex;
  .v-btn {
    width: 250px;
  }
  .v-btn {
    color: #fff;
  }
  .btn-cancel {
    background: #f4f4f4 !important;
    color: #000 !important;
  }
}
.addtagbtn {
  margin-bottom: 20px;
}
.loadingBox {
  width: 100%;
  display: flex;
  justify-content: center;
}
.addItemBtn {
  width: 200px;
}
.tagbox {
  background: #fff;
  &-sub {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
    .v-btn {
      margin-bottom: 30px;
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  .addtagbtn {
    margin-top: 20px;
    margin-left: 10px;
  }
}

.combobox {
  background: #ededed;
  padding: 10px;
  margin-bottom: 20px;
}

.cancel-text {
  color: rgba(91, 61, 232, 1);
  font-weight: 500;
  cursor: pointer;
}

.images-list {
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 10px;
  }
}
.relBox {
  position: relative;
}
.imageLoop {
  margin-top: 10px;
  position: relative;
  .v-icon {
    position: absolute;
    background: black;
    color: #fff !important;
    right: -10px;
    top: -10px;
    border-radius: 50%;
  }
  img {
    width: 80px;
    height: 85px;
    object-fit: contain;
    border: 1px solid #d1cccc;
  }
}
.submitbtn {
  text-align: left;
  margin-left: 0 !important;
}

.nextbtn {
  margin-left: 10px;
}

h3 {
  margin-bottom: 20px;
}
.addtagbtn {
  background: rgb(57, 49, 170) !important;
  color: #fff;

  margin-bottom: 10px;
  font-size: 12px !important;
  .v-icon {
    font-size: 16px !important;
  }
}
.widthFull {
  width: 100%;
  background: #faf8f8;
  padding: 15px 10px;
  margin: 10px 15px;
}
.tagbox {
  background: #fff;
  &-sub {
    display: flex;
    flex-flow: row-reverse;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    padding: 10px;
    margin-bottom: 10px;
    .v-btn {
      margin-bottom: 30px;
      margin-left: 20px;
      margin-top: 10px;
    }
  }
  .addtagbtn {
    margin-top: 20px;
    margin-left: 10px;
  }
}

.combobox {
  background: #ededed;
  padding: 10px;
  margin-bottom: 20px;
}

.spacebottom {
  margin-bottom: 30px;
}

.videolists {
  margin-top: -10px;
  .videobox {
    flex-wrap: wrap;
    .imagebox {
      @media only screen and (max-width: 991px) {
        width: 110px;
      }
    }
  }
}
video {
  height: 83px;
}
.nextbtn {
  margin-left: 0;
}

.popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 0 30px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
  display: flex;
  align-items: center;
  justify-content: center;
  .imagebox {
    background: white;
    border: 1px dashed #000;
  }
}
header {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .v-icon {
    width: 30px;
    height: 30px;
    background: rgb(59, 59, 59);
    border-radius: 50%;
    color: #fff;
    font-size: 14px;
  }
}
.no-paddinglr {
  padding: 0 5px !important;
}
.inputbox {
  .v-text-field {
    background: #fefefe;
  }
}
.imagesFlex {
  border: 1px dashed #7e6b6b;
  background: #f4f4f4 !important;
  border-radius: 5px;
  .imagebox {
    span {
      font-size: 14px;
      padding: 10px;
      display: block;
      bottom: -5px;
      &:first-of-type {
        font-size: 15px;
      }
    }
  }
}
.flexBox {
  display: flex;
  &-space {
    justify-content: space-between;
    align-items: center;
  }
}

.cameraPlus {
  font-size: 40px;
  color: #7e6b6b !important;
}
.videoPlus {
  font-size: 50px;
  margin-bottom: -10px;
  color: #7e6b6b !important;
}

.btn-danger {
  background: red !important;
  min-height: 50px;
  color: #fff;
  width: 200px;
}

.flexBox {
  display: flex;
  position: relative;
  &.column {
    position: relative;
    flex-flow: column;
  }
  &-space {
    justify-content: space-between;
    align-items: center;
  }
  .v-icon-miuns {
    position: absolute;
    right: -35px;
    top: 13px;
    background: red;
    color: #fff;
    border-radius: 5px;
    padding: 3px;
  }
}
.imageAdded {
  position: relative;
  .v-icon {
    position: absolute;
    background: rgb(224, 11, 11);
    border-radius: 50%;
    right: 20px;
    top: 10px;
    font-size: 16px;
    height: 25px;
    width: 25px;
    cursor: pointer;
    color: #fff;
  }
  img,
  video {
    width: 200px;
    height: 150px;
    object-fit: contain;
    border: 1px solid #efefef;
    margin-right: 10px;
  }
}
.v-btn {
  color: #fff;
}
</style>
