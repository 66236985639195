<template>
  <section>
    <div class="px-3">
      <div class="pb-0 flexBox flexBox-space">
        <strong>Variant</strong>
        <v-btn @click="addProperty">
          <v-icon> mdi-plus </v-icon>
        </v-btn>
      </div>
    </div>
    <div v-if="loading">Loading...</div>
    <div v-if="!loading">
      <v-form
        ref="propertyForm"
        v-for="(data, index) in allProperties"
        :key="index + 'property'"
      >
        <v-col md="12" cols="12" class="pb-5">
          <div class="mb-8">
            <vue-dropzone
              ref="myVueDropzone"
              id="dropzone"
              :options="dropzoneOptions"
            ></vue-dropzone>
          </div>
          <div class="flexBox">
            <v-text-field
              class="inputbox"
              v-model="data.propertyName"
              label="Variant Name"
              outlined
            ></v-text-field>
          </div>
          <v-row>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.price"
                label="Price"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.color"
                label="Color"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-select
                class="inputbox"
                v-model="motor"
                label="Motor Type"
                outlined
                :items="motorTypes"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="range"
                label="Range (km)"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="batteryCapacity"
                label="Battery Capacity"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="batteryType"
                label="Battery Type"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="acceleration"
                label="Acceleration (0-100kmph)"
                @keyup.enter.stop="validate"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" class="pb-0" v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.mileage"
                label="Mileage"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.engine"
                label="Engine"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.bhp"
                label="BHP"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.transmissionType"
                label="Transmission Type"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.seatingCapacity"
                label="Seating Capacity"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.cityMileage"
                label="City Mileage"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.fuelType"
                label="Fuel Type"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.engineDisplacement"
                label="Engine Displacement"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.numberOfCylinder"
                label="Number of cylinder"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.power"
                label="Max Power"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.torque"
                label="Max Torque"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.bootSpace"
                label="Boot Space"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0"  v-if="motor == 'hybrid'">
              <v-text-field
                class="inputbox"
                v-model="data.fuelTank"
                label="Fuel Tank"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0" >
              <v-text-field
                class="inputbox"
                v-model="data.bodyType"
                label="Body Type"
                outlined
              ></v-text-field>
            </v-col>
            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.groundClearance"
                label="Ground Clearance"
                outlined
              ></v-text-field>
            </v-col>

            <v-col md="4" cols="4" class="pb-0">
              <v-text-field
                class="inputbox"
                v-model="data.serviceCost"
                label="Service Cost"
                outlined
              ></v-text-field>
            </v-col>
          </v-row>

          <div>
            <v-btn
              class="btn-danger mt-5 mb-5 removeBtn"
              @click="removeProperty(index)"
              >Remove</v-btn
            >
          </div>
        </v-col>
      </v-form>
    </div>
  </section>
</template>

<script>
import "vue-croppa/dist/vue-croppa.css";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddProperty",
  data: () => ({
    numberRule: [(v) => !isNaN(v) || "Field Should be Number"],
    loading: false,
    properties: [],
    dropzoneOptions: {
      url: "https://httpbin.org/post",
      thumbnailWidth: 150,
      maxFilesize: 0.5,
      headers: { "My-Awesome-Header": "header value" },
    },
    propertyName: undefined,
    color: undefined,
    mileage: undefined,
    engine: undefined,
    bhp: undefined,
    transmission: undefined,
    fuelTank: undefined,
    engineDisplacement: undefined,
    numberOfCylinder: undefined,
    cityMileage: undefined,
    torque: undefined,
    bodyType: undefined,
    power: undefined,
    seatingCapacity: undefined,
    transmissionType: undefined,
    bootSpace: undefined,
    fuelType: undefined,
    groundClearance: undefined,
    serviceCost: undefined,
    motorTypes: [
      { name: "EV", value: "ev" },
      { name: "Hybrid", value: "hybrid" },
    ],
    motor: undefined,
    batteryType: undefined,
    batteryCapacity: undefined,
    range: undefined,
    acceleration: undefined,
  }),
  components: {
    vueDropzone: vue2Dropzone,
  },
  methods: {
    ...mapActions(["setProperties"]),
    addProperty() {
      this.setProperties({
        price: undefined,
        propertyName: undefined,
        mileage: undefined,
        engine: undefined,
        bhp: undefined,
        transmission: undefined,
        fuelTank: undefined,
        engineDisplacement: undefined,
        numberOfCylinder: undefined,
        torque: undefined,
        bodyType: undefined,
        power: undefined,
        seatingCapacity: undefined,
        transmissionType: undefined,
        bootSpace: undefined,
        fuelType: undefined,
        groundClearance: undefined,
        serviceCost: undefined,
      });
    },
    removeProperty(index) {
      this.properties.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allProperties"]),
  },
  async created() {},
};
</script>

<style lang="scss" scoped>
section {
  width: 100%;
}
.flexBox {
  display: flex;
  &-space {
    justify-content: space-between;
    align-items: center;
  }
  .v-btn {
    background: #0878d4 !important;
  }
}
.imagesFlex {
  border: 1px dashed #0878d4;
  background: #f4f4f4 !important;
  border-radius: 5px;
  .imagebox {
    span {
      font-size: 14px;
      padding: 10px;
      display: block;
      bottom: -5px;
      &:first-of-type {
        font-size: 15px;
      }
    }
  }
}
.cameraPlus {
  font-size: 40px;
}
.images-list {
  display: flex;
  flex-wrap: wrap;
  div {
    margin-right: 10px;
  }
}
strong {
  font-size: 18px;
}
.imageLoop {
  margin-top: 10px;
  position: relative;
  .v-icon {
    position: absolute;
    background: black;
    color: #fff;
    right: -10px;
    top: -10px;
    border-radius: 50%;
  }
  img {
    width: 80px;
    height: 85px;
    object-fit: contain;
    border: 1px solid #d1cccc;
  }
}
.v-btn.submitbutton {
  color: #fff !important;
}
.removeBtn {
  background: red !important;
}
</style>